import { useRef, useEffect } from "react";

const useMutable = <T extends {}>(x: T) => {
  const ref = useRef(x);

  useEffect(() => {
    ref.current = x;
  }, [x]);

  return ref;
};

export default useMutable;
