import React, { useReducer } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useData } from '../state/DataProvider';
import Links from './Links';
import styled from 'styled-components';

const SideBar = () => {
  const [more, toggle] = useReducer(x => !x, false);
  const { projects } = useData();
  const { pathname } = useLocation();
  const visible = more ? projects.slice(6) : projects.slice(0, 6);

  return (
    <Wrapper>
      <Links />
      <div>
        <p>work</p>
        {visible.map(prj => {
          const path = prj.title.replace(/\s/g, '-');
          return <div key={path}><StyledLink selected={path === pathname.slice(1)} to={path}>{prj.title}</StyledLink><br /></div>
        })}
        <p className="link" onClick={toggle} >{more ? '...prev' : 'next...'}</p>
      </div>
    </Wrapper >
  )
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  padding: 1rem;
  box-sizing: border-box;
  @media (max-width: 700px) {
    display: none;
  }
`

const StyledLink = styled(Link) <{ selected: boolean }>`
  width: 100%;
  ${props => props.selected ? `
    text-decoration: line-through;
    font-style: italic;
  ` : ''}
`
export default SideBar;
