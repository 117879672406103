import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    @import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

    body {
        margin: 0;
        font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
            "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
            "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    h1 {
        font-size: 20px;
        font-weight: normal;
    }

    h2 {
        text-align: center;
        color: blue;
        font-weight: normal;
    }

    h3 {
        font-weight: normal;
        font-size: 2rem;
    }

    a, .link {
        text-decoration: none;
        color: blue;
        &:visited {
            color: blue;
        }
        &:hover {
            font-style: italic;
        }
    }

   a, p, span {
       font-size: 2vw;
       line-height: 3.4vw;
   }
   @media (max-width: 700px) {
        a, p, span {
            font-size: 18px;
            line-height: 2rem;
        }
   }
`

export default GlobalStyle
