import React from 'react';
import styled from 'styled-components';

const BrowserBar = ({ children, ...props }: { children: React.ReactNode }) => {
    return (
        <Wrapper>
            <Bar><span className="red" /><span className="orange" /><span className="green" /></Bar>
            {children}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    box-shadow: 0px 0px 7px 0px #bbb;
    background-color: transparent;
`

const Bar = styled.div`
    width: 100%;
    height: 1rem;
    background: #cccccc;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: center;

    span { width: .4rem; height: .4rem; border-radius: 50%; margin-left: .3rem; }
    .red { background-color: red }
    .orange { background-color: orange }
    .green { background-color: green }

    @media (max-width: 700px) {
        display: none;
    }
`;

export default BrowserBar;
