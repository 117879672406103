import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useScrollLock from '../utils/useScrollLock';

const Modal = ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) => {
  const [loaded, setLoaded] = useState(false);

  useScrollLock(loaded);

  useEffect(() => {
    setLoaded(true)
    return () => {
      setLoaded(false)
    }
  }, [])

  return <Wrapper loaded={loaded} {...props}>{children}</Wrapper>
}

export default Modal;

const Wrapper = styled.div<{ loaded: boolean }>`
  width: 100%;
  height: 100vh;
  transition: opacity .3s;
  background: white;
  opacity: ${props => props.loaded ? 1 : 0};
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: scroll;
`
