import React, { createContext, useContext } from 'react';
import { SiteData } from '../utils/types';

const DataContext = createContext<SiteData>({} as any);

export const DataProvider = ({ data, children }: { data: SiteData, children: React.ReactNode }) => {
  return (
    <DataContext.Provider value={data}>{children}</DataContext.Provider>
  )
}

export const useData = () => useContext(DataContext);

export default DataProvider;
