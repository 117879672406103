import { Redirect, Link, Route, useParams } from 'react-router-dom';
import styled from 'styled-components';
import React, { useRef, useEffect, useState } from 'react';

import { useData } from '../state/DataProvider';
import Markdown from './Markdown';
import Gallery from './Gallery';
import Modal from './Modal';
import { useProject } from '../utils/selectors';
import GalleryModal from './GalleryModal';

const ProjectPage = () => {
  const project = useProject()

  useScrollReset();

  if (!project) return <Redirect to="/" />
  return (
    <div>
      <Header />
      <Title>{project && project.title}</Title>
      <Markdown source={project?.content} />
      {project?.gallery ? <Gallery images={project.gallery} /> : null}
      <Route path="/:title/:imageId" component={GalleryModal} />
    </div>
  )
}

const useScrollReset = () => {
  const { title } = useParams();
  const last = useRef<string | undefined>()

  useEffect(() => {
    if (last.current !== title) {
      window.scrollTo(0, 0);
    }
    last.current = title;
  }, [title])
}

const Title = styled.p`
  position: sticky;
  top: 0;
  background: white;
  z-index: 11;
  max-width: 80%;
  padding: .5rem 0rem;
  padding-top: 1rem;
  @media (min-width: 700px) {
    display: none;
  }
`;

const Header = () => {
  const { projects } = useData();
  const [modal, setModal] = useState(false);
  const { title } = useParams();

  useEffect(() => {
    return () => setModal(false);
  }, [title])

  return (
    <Wrapper>
      <HeaderWrapper open={modal}><p><Link to="/">dan beaven</Link></p><p onClick={() => setModal(!modal)} style={{ color: 'blue' }}>work</p></HeaderWrapper>
      {modal && <Modal style={{ zIndex: 12, marginTop: '3rem', padding: '1rem', background: 'linear-gradient(180deg, white, #ff0000)' }}>
        <div>
          {projects.map((proj, i) => <ProjectLink key={proj.title}><Link to={proj.title.replace(/\s/g, '-')}>{proj.title}</Link></ProjectLink>)}
        </div>
      </Modal>}
    </Wrapper>
  )
}

const ProjectLink = styled.div`
  padding: .4rem 0;
`

const HeaderWrapper = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: ${props => props.open ? 13 : 11};
  background: white;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  & > p { margin: 0; padding-top: 1rem; padding-bottom: .5rem; }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding-top: 3rem;
  @media (min-width: 700px) {
    display: none;
  }
`;

export default ProjectPage;
