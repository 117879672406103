import { ProjectData } from "./types";
import { useParams } from "react-router-dom";
import { useData } from "../state/DataProvider";

export const getTitleSlug = (title: string) => title.replace(/\s/g, "-");
export const getProject = (title: string, projects: ProjectData[]) =>
  projects.reduce<ProjectData | undefined>(
    (a, b) => (title === getTitleSlug(b.title) ? b : a),
    undefined
  );

export const useProject = () => {
  const { projects } = useData();
  const { title } = useParams<{ title: string }>();

  return getProject(title, projects);
};

export const head = <T extends any>(arr: T[]) => arr && arr[0];
