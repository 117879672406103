import { useEffect } from "react";

const useScrollLock = (active: boolean) => {
  useEffect(() => {
    if (!active) return;
    let overflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = overflow;
    };
  }, [active]);
};

export default useScrollLock;
