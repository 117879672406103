import React from 'react';
import { GalleryImage } from '../utils/types';
import styled from 'styled-components';

import { useInView } from 'react-intersection-observer'
import { useHistory, useLocation } from 'react-router-dom';
import LazyImage from './LazyImage';

const Gallery = ({ images }: { images: GalleryImage[] }) => {
  const [column1, column2] = images.reduce<[GalleryImage[], GalleryImage[]]>((a, b, i) => i % 2 === 0 ? [a[0], [...a[1], b]] : [[...a[0], b], a[1]], [[], []]);
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <ListWrapper>
        {column1.map(img => <li key={img.src}><ImageWrapper {...img} /></li>)}
      </ListWrapper>
      <ListWrapper>
        {column2.map(img => <li key={img.src}><ImageWrapper {...img} /></li>)}
      </ListWrapper>
    </div>
  )
}

const ImageWrapper = ({ src, srcset, id }: GalleryImage) => {
  const [ref, inView] = useInView({ threshold: 0 })
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <ImageWrap ref={ref} onClick={() => history.push(`${pathname}/${id}`)}>
      <div id={id}>
        <LazyImage src={src} srcSet={srcset} alt="presentation" wait={!inView} />
      </div>
    </ImageWrap>
  )
}
const ImageWrap = styled.div`
  position: relative;
  z-index: initial;
  outline: 2vw solid white;
  margin: 1rem;
  pointer-events: none;
  & > div {
    position: relative;
    z-index: -1;
    transition: 1s;
    pointer-events: auto;
    cursor: pointer;
  }
  & > div > img {
    width: 100%;
    position: relative;
    transform: scale(1.05, 1.05);
    transition: 4s ease;
    user-select: none;
    vertical-align: middle;
    &:hover { transform: scale(1, 1); }
  }
  @media (max-width: 700px) {
    outline: none;
    margin: 0;
    & > div {
      padding-bottom: .5rem;
    }
    & > div > img {
      transform: scale(1, 1);
    }
  }
`;

const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  flex: 50%;
  display: flex;
  flex-flow: column;
  list-style-type: none;
  & > li {
    position: relative;
    z-index: 10;
  }
  @media (max-width: 700px) {
      padding-right: .5rem;
  }
`

export default Gallery;
