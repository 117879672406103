import { useParams, Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import React, { useEffect, useState, useMemo } from 'react';
import { useSpring, animated } from 'react-spring';

import { useProject } from '../utils/selectors';
import useMutable from '../utils/useMutable';
import { getCoords } from '../utils/dom';

import backIcon from '../images/icons/back.png';
import cancelIcon from '../images/icons/cancel.png';
import nextIcon from '../images/icons/next.png';
import { GalleryImage } from '../utils/types';
import LazyImage from './LazyImage';
import useScrollLock from '../utils/useScrollLock';

const GalleryModal = () => {
  const [out, setOut] = useState(true);
  const project = useProject();
  const { imageId } = useParams<{ imageId: string }>()

  const [prev, current, next] = useMemo(() => {
    const gallery = (project?.gallery || []);
    return gallery.reduce<GalleryImage[]>((all, current, i) => {
      if (all.length > 0 || current.id !== imageId) return all;
      const prev = i <= 0 ? gallery[gallery.length - 1] : gallery[i - 1];
      const next = gallery[(i + 1) % gallery.length];
      return [prev, current, next]
    }, [])
  }, [imageId, project])

  useScrollLock(!!imageId)
  useImageScroll(imageId)

  const icon = useMouseControls(prev.id, next.id);
  const [offsetX, offsetY] = useTouchControls(prev.id, next.id);

  useEffect(() => {
    setOut(false);
    return () => setOut(true);
  }, [])

  const offset = out ? 0 : Math.abs(offsetY) > 50 ? (1 - ((Math.abs(offsetY) - 50) / window.innerHeight)) : 1
  const wrapperStyle = useSpring({
    immediate: offset !== 0 && offset !== 1,
    to: {
      opacity: offset,
      backgroundColor: `rgba(9,9,9, ${Math.abs(offsetY) > 50 ? '0' : '1'})`,
      transform: `scale(${offset}, ${offset})`
    }
  })

  return (
    <Wrapper icon={icon} style={wrapperStyle} out={out}>
      {!project && <Redirect to="/" />}
      <Container key={prev.id} offsetX={offsetX} position={'left'}><Img srcSet={prev.srcset} src={prev.src} /></Container>
      <Container key={current.id} offsetX={offsetX} position={'center'}><Img srcSet={current.srcset} src={current.src} /></Container>
      <Container key={next.id} offsetX={offsetX} position={'right'}><Img srcSet={next.srcset} src={next.src} /></Container>
    </Wrapper>
  )
}

const Wrapper = styled(animated.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 66;
  cursor: url("${(props: any) => props.icon}"), auto;
` as any;

const Container = styled.div.attrs<{ position: 'left' | 'center' | 'right', offsetX: number }>(props => ({
  style: {
    transition: props.offsetX !== 0 ? 'none' : '.3s',
    transform: `translateX(
    ${props.position === 'left'
        ? `calc(-100vw + ${props.offsetX}px)` : props.position === 'right'
          ? `calc(100vw + ${props.offsetX}px)` : `calc(0vw + ${props.offsetX}px)`
      })`
  }
}))`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    margin-top: -2rem;
    padding-bottom: 2rem;
  }
` as any;

const Img = styled(LazyImage)`
  max-height: 100vh;
  max-width: 100%;
`

const useImageScroll = (id: string) => {
  useEffect(() => {
    return () => {
      const elem = document.getElementById(id);
      if (elem) {
        const coords = getCoords(elem)
        window.scrollTo({
          left: 0,
          top: coords.top - (window.innerHeight / 2 - coords.height / 2),
          behavior: 'smooth'
        })
      }
    }
  }, [id])
}

const useTouchControls = (back: string, next: string) => {
  const [offsetX, setXOffset] = useState(0);
  const [offsetY, setYOffset] = useState(0);
  const history = useHistory();
  const { title } = useParams<{ title: string }>();

  const mutable = useMutable({ back, next });

  useEffect(() => {
    let startX = 0;
    let startY = 0;
    const handleStart = (e: TouchEvent) => {
      startX = -e.touches[0].clientX;
      startY = -e.touches[0].clientY;
    }
    const handleMove = (e: TouchEvent) => {
      setYOffset(startY + e.touches[0].clientY);
      setXOffset(startX + e.touches[0].clientX);
    }
    const handleEnd = () => {
      startX = 0;
      startY = 0;
      setYOffset(x => {
        if (Math.abs(x) > 200) {
          setTimeout(() => {
            history.push(`/${title}`)
          }, 100);
          return 500;
        }
        return 0;
      })
      setXOffset(x => {
        if (x > 25) setTimeout(() => history.replace(mutable.current.back), 0);
        if (x < -25) setTimeout(() => history.replace(mutable.current.next), 0);
        return 0;
      })
    }
    window.addEventListener('touchstart', handleStart)
    window.addEventListener('touchmove', handleMove)
    window.addEventListener('touchend', handleEnd)
    return () => {
      window.removeEventListener('touchstart', handleStart)
      window.removeEventListener('touchmove', handleMove)
      window.removeEventListener('touchend', handleEnd)
    }
  }, [mutable, history, title])

  return [offsetX, offsetY];
}

const useMouseControls = (back: string, next: string) => {
  const [icon, setIcon] = useState(cancelIcon);
  const history = useHistory();
  const { title } = useParams<{ title: string }>();

  const mutable = useMutable({ back, next });

  useEffect(() => {
    const handleMove = (e: MouseEvent) => {
      if (e.clientX < window.innerWidth * .3) return setIcon(backIcon)
      if (e.clientX > window.innerWidth * .7) return setIcon(nextIcon)
      return setIcon(cancelIcon);
    }
    const handleClick = (e: MouseEvent) => {
      if (e.clientX < window.innerWidth * .3) return history.replace(mutable.current.back)
      if (e.clientX > window.innerWidth * .7) return history.replace(mutable.current.next)
      return history.push(`/${title}`)
    }
    window.addEventListener('click', handleClick)
    window.addEventListener('mousemove', handleMove)
    return () => {
      window.removeEventListener('click', handleClick)
      window.removeEventListener('mousemove', handleMove)
    }
  }, [title, history, mutable])

  return icon;
}

export default GalleryModal;
