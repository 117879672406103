import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

let alreadyLoaded: { [x: string]: boolean } = {};

const LazyImage = ({ src, alt, wait, srcSet, ...props }: { src: string, alt?: string, wait?: boolean } & React.ImgHTMLAttributes<HTMLImageElement>) => {
  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (wait) return;
    if (alreadyLoaded[src]) {
      setLoaded(true);
      setVisible(true)
    };
    let mounted = true;
    let img = new Image();

    if (srcSet) { img.srcset = srcSet; }
    else { img.src = src; }

    img.onload = () => {
      if (!mounted) return;
      setLoaded(true);
      alreadyLoaded = { ...alreadyLoaded, [src]: true }
      setTimeout(() => {
        if (!mounted) return;
        setVisible(true)
      }, 100)
    }
    return () => { mounted = false }
  }, [src, srcSet, wait])

  if (loaded) return <Img src={src} alt={alt} srcSet={srcSet} visible={visible} {...props} />
  return <Placeholder />
}

const Img = styled.img<{ visible: boolean }>`
  opacity: ${props => props.visible ? 1 : 0};
  transform: scale(${props => props.visible ? "1,1" : '0.9,0.9'});
  transition: 1s;
  transition-delay: .5s;
`

const background = keyframes`
  0%{ background-position: 0% 50% }
  100%{ background-position: 130% 50% }
`;

export const Placeholder = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;

  max-width: 100%;
  margin: 0 auto;
  border: 10px solid white;
  background: linear-gradient(270deg, white, red, white, blue);
  background-size: 400% 400%;
  animation: ${background} 2.5s linear infinite;
`


export default LazyImage
