import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Helmet from 'react-helmet';

import App from './components/App';
import DataProvider from './state/DataProvider';
import * as serviceWorker from './utils/serviceWorker';

import data from './data.json';

const AppRouter = () => (
    <Router>
        <Helmet title="Dan Beaven">
            <meta name="description" content="Portfolio of multi disceplinary designer, artist and software engineer Dan Beaven." />
            <meta name="author" content="Dan Beaven" />
        </Helmet>
        <Switch>
            <Route path="/">
                <DataProvider data={data}>
                    <App />
                </DataProvider>
            </Route>
        </Switch>
    </Router>
)

ReactDOM.render(<AppRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
