import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown/with-html';

import BrowserBar from './BrowserBar';
import LazyImage from './LazyImage';

const Image = ({ alt, src }: { alt: string, src: string }) => {
    const config = /\[(.*?)\]/gmi.exec(src);
    const img = <LazyImage alt={alt} src={src} />;
    if (config && config[1] && config[1] === 'desktop') return <ImageWrapper><BrowserBar>{img}</BrowserBar></ImageWrapper>
    return <ImageWrapper>{img}</ImageWrapper>;
}

const Paragraph = (props: any) => {
    if (props.children && props.children[0] && props.children[0].props.src) return props.children[0];
    return <p>{props.children}</p>
}

const Markdown = (props: { source?: string }) => {
    return <Wrapper><ReactMarkdown {...props} escapeHtml={false} renderers={{
        image: Image,
        paragraph: Paragraph
    }} /></Wrapper>
}

const Wrapper = styled.div`
    margin: 0 auto;
    img { max-width: 100%; max-height: 90vh; }
    p { padding: 0 1rem; }
    iframe { width: 100%; height: 24rem; margin: 2rem auto; }
    table { width: 100%; max-width: 40rem; }
    thead { text-align: left; }
    td, th { width: 50rem; }

    & > * { margin: 0rem auto 2rem auto; }
    .w10 { max-width: 10rem; }
    .w20 { max-width: 20rem; }
    .w30 { max-width: 30rem; }
    .w40 { max-width: 40rem; }
    .w50 { max-width: 50rem; }
    .mh50 { & img {max-height: 50vh; }}
    .flex { display: flex; align-items: flex-start; justify-content: center; }

    @media (max-width: 700px) {
        table { display: none }
        p { margin-bottom: 1rem; padding: 0; }
    }
`

const ImageWrapper = styled.div`
    margin: 2rem 1rem 2rem 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 700px) {
        margin: 0.5rem;
    }
`;

export default Markdown
