import React, { useReducer } from 'react';
import { useData } from '../state/DataProvider';
import { Link } from 'react-router-dom';

const Links = () => {
  const [showing, toggleShowing] = useReducer(x => !x, false);
  const { links } = useData();
  return (
    <div>
      <p style={{ margin: '0', marginBottom: '.5rem' }}><Link to="/">{'dan beaven'}</Link></p>
      <br />
      <p className="link" style={{ margin: '0rem 0', cursor: 'pointer', fontStyle: showing ? 'italic' : 'normal' }} onClick={toggleShowing}>{showing ? '[links' : '[links...]'}</p>
      {/* <Arrow active={showing} style={{ fontStyle: showing ? 'italic' : 'normal' }}>-></Arrow> */}
      <div style={{ paddingLeft: '1rem' }}>
        {showing && links.map((link, i) => (<div key={link.href}><a href={link.href} rel="noopener noreferrer" target="_blank">{link.label}{(i + 1) === links.length ? ']' : ''}</a></div>))}
      </div>
      {/* <p><Link to="/photography">photos</Link></p> */}
    </div>
  )
}

export default Links;
