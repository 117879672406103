import { Route, Switch, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import GlobalStyle from './GlobalStyles';
import ProjectPage from './ProjectPage';
import SideBar from './SideBar';
import Home from './Home';

ReactGA.initialize('UA-115969607-1');

const useReScroll = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/') return;
    ReactGA.pageview(window.location.pathname);
  }, [pathname])
}

const App = () => {
  useReScroll()

  return (
    <Wrapper>
      <GlobalStyle />
      <SideBar />
      <div />
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/:title" component={ProjectPage} />
        </Switch>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 1rem;
  box-sizing: border-box;
  width: 100vw;

  display: grid;
  grid: 1fr / 30vw 1fr;
  & > div:nth-child(2) {
  }
  @media (max-width: 700px) {
    grid: 1fr / 1fr;
    height: auto;
    & > div:nth-child(1) { display: none; }
    & > div:nth-child(2) {
      height: auto;
      overflow-y: hidden;
    }
  }
`;

export default App;
