import { Link } from 'react-router-dom';
import styled from 'styled-components';
import React, { useReducer } from 'react';

import { useData } from '../state/DataProvider';

const Home = () => {
  const [more, toggle] = useReducer(x => !x, false);
  const { projects } = useData();
  const visible = more ? projects : projects.slice(0, 5);

  return (
    <div>
      <Header />
      <div style={{ marginTop: '11vw' }} />
      <p>Dan is an artist and designer and software developer.</p>
      <p>His recent work includes <Link to="/lectures.london">lectures.london</Link> a platform tracking free, open to the plublic lectrues in london, <Link to="/told-by-turks">“Told by Turks”</Link> an experimental documentary exporing online clickwork.</p>
      <Mobile>
        <p style={{ margin: '4rem auto', textAlign: 'center' }}>work</p>
        <Projects>
          {visible.map(x => (
            <div key={x.title.replace(/\s/g, '-')}>
              <Link to={x.title.replace(/\s/g, '-')} style={{ fontStyle: 'italic' }}>{x.title}</Link>
              <p>{x.short_description}</p>
            </div>
          ))}
        </Projects>
        <p style={{ margin: '4rem auto', textAlign: 'center', color: 'blue' }} onClick={toggle}>{more ? 'less' : 'more'}</p>
      </Mobile>
    </div>
  )
}

const Header = styled((props) => {
  const { links } = useData();
  return (
    <div {...props}>
      <p style={{ margin: 0 }}>dan beaven</p>
      <div style={{ paddingLeft: '1rem' }}>
        {links.map((link, i) => (<div key={link.href}><a href={link.href} rel="noopener noreferrer" target="_blank">{link.label}</a></div>))}
      </div>
    </div>
  )
})`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;

  display: none
  & > div { text-align: right }
  @media (max-width: 700px) {
    display: flex
  }
`;

const Projects = styled.div`
  display: flex;
  flex-flow: row wrap;

  & > div { width: 61.8%; margin-bottom: 1.5rem; text-align: right; }
  & > div:nth-child(even) { margin-left: 38.2%; text-align: left; }
  & > div > p { margin-top: .4rem; max-width: 20rem }
`;

const Mobile = styled.div`
  @media (min-width: 700px) { display: none }
`
export default Home
